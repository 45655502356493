









































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import cellRender from './CellRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './header.js';
import TagSelect from './userTagCell.vue';
import UserSelect from './userSelectCell.vue';
import { param } from 'jquery';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    UserSettings: () => getComponent('common/UserSettings'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TagSelect,
  },
})
export default class Groups extends Vue {
   windowWidth = window.innerWidth;

   activeTableID: any = null;

    dataLoading = false;

   activeWorkspaceID: any = null;

   newColumnName = '';

   newGroupName = '';

  showCreateGroupModal =false;

  selectedWellNames = [];

  availableWellNames = [];

  tempGroups = ['2021 Group 1', 'Group 3', 'Group 4']

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = []

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   newCloumnType = '';

   columnDefs: any = [
     {
       field: 'wellName', pinned: 'left', sortable: true, filter: true, headerName: 'Well Name', width: 300, editable: true, checkboxSelection: true,
     },
     {
       field: 'Add Column +',
       sortable: true,
       pinned: 'right',
       headerComponent: 'agColumnHeader1',
       editable: false,

       initialWidth: 150,
       cellRendererFramework: 'cellRender',
       cellRendererParams: {
         clicked(field) {
           alert(`${field} was clicked`);
         },
       },
     },

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    async deleteGroup(group){
      let params = {group_id : group.ID}
      await scheduleModule.deleteGroup(params)
       await scheduleModule.getTableGroups({table_id: this.activeTableID})
    }

    getGroupTags(group){
      // console.log(group.Columns.find(c => c.))
      console.log(group.Payload)
      return ['Tag1', 'Tag2']
    }

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    get activeTableGroups() {
      return scheduleModule.activeTableGroups
      
    }


    goToDetailPage(group){
      console.log(group)
      this.$router.push({ name: 'GroupDetail', params: { id:group.ID} })
	  // @ts-ignore
      scheduleModule.setActiveGroup(group)
    }

    async createGroup() {
      const rowData = this.selectedWellNames.map((name) => ({ Well: name, Tags: this.selectedTags }));

      const columnData = [
        {
          ColumnName: 'Well',
          ColumnType: 'WELL',
          CustomName: 'Well Name',
        },

        {
          ColumnName: 'Tags',
          ColumnType: 'TAGS',
          CustomName: 'Tags',
        },
      ];

      const groupObj:any = {
        username: accountModule.user.email.toLowerCase(),
        workspace_id: this.activeWorkspaceID,
        table_id: this.activeTableID,
        columns: JSON.stringify(columnData),
        payload: JSON.stringify(rowData),
        group_name: this.newGroupName,
        usage: 'ASSETS',
      };

      await scheduleModule.postNewTableGroupPage(groupObj);

      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;

      await scheduleModule.getTableGroups({table_id: this.activeTableID})
    }

   

    get myView() {
      return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
    }

    beforeMount() {
      workspaceModule.setActivePage('Groups');
      scheduleModule.setActivePage('Groups');
      this.context = { componentParent: this };
    }

   

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get enabledWells() {
      return assetsModule.enabledWells;
    }

    async created() {
       this.dataLoading = true;
      await assetsModule.getEnabledWells();
      await workspaceModule.getWorkspaces();

      console.log(workspaceModule.activeWorkspace);
      const workspace: any = workspaceModule.activeWorkspace;
      if (workspace?.Tables.length < 1) {
        await scheduleModule.postNewWorkspaceTable({
		  username: accountModule.user.email.toLowerCase(),
		  // @ts-ignore
		  workspace_id: workspaceModule.activeWorkspace.ID,
		  table_title: 'Asset Table',
	  });
      } else {
        this.activeTableID = workspace?.Tables[0].ID;
        this.activeWorkspaceID = workspace?.Tables[0].WorkspaceID;
      }

      if (this.activeTableID) {
        await scheduleModule.getTableGroups({ table_id: this.activeTableID });
      }
      this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);
       this.dataLoading = false;
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      workspaceModule.setActivePage(null);
      scheduleModule.setActivePage(null);
      window.removeEventListener('resize', this.onResize);
    }

    addColumn() {
      console.log('add');
      console.log(this.newColumnName);
      if (this.newColumnName) {
        this.columnDefs.push({ field: this.newColumnName });
        this.newColumnName = '';
      }
    }

    addRow() {
      console.log('add');
      if (this.newWellName) {
        this.rowData.push({ wellName: this.newWellName });
        this.newWellName = '';
      }
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }
}

